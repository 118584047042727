/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.a-primary-color{
    color:  #B79086;
}

.a-secondary-color{
    color:  #577778;
}

.green-snackbar {
    --mdc-snackbar-container-color: #E1F8F0;
    --mdc-snackbar-supporting-text-color: #212B36;
    border-left: 6px solid #34D399;
}
::-ms-reveal {
    display: none;
    /*elimina el show password de microsoft edge para poder usar el de luna nueva*/
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.mat-mdc-text-field-wrapper{
  height:inherit !important
}
