/* For use in src/lib/core/theming/_palette.scss */
$md-coffee: (
        50 : #e7e4e4,
        100 : #c3bcbc,
        200 : #9b9090,
        300 : #736464,
        400 : #554242,
        500 : #372121,
        600 : #311d1d,
        700 : #2a1818,
        800 : #231414,
        900 : #160b0b,
        A100 : #ff5959,
        A200 : #ff2626,
        A400 : #f20000,
        A700 : #d90000,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #ffffff,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

