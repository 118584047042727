/* For use in src/lib/core/theming/_palette.scss */
$md-yellow: (
        50 : #f9f7f1,
        100 : #f0ecdd,
        200 : #e7dfc7,
        300 : #ddd2b0,
        400 : #d5c99f,
        500 : #cebf8e,
        600 : #c9b986,
        700 : #c2b17b,
        800 : #bca971,
        900 : #b09b5f,
        A100 : #ffffff,
        A200 : #fff9ea,
        A400 : #ffecb7,
        A700 : #ffe59d,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #000000,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

