/* For use in src/lib/core/theming/_palette.scss */
$md-red: (
        50 : #f6f2f0,
        100 : #e9dedb,
        200 : #dbc8c3,
        300 : #cdb1aa,
        400 : #c2a198,
        500 : #b79086,
        600 : #b0887e,
        700 : #a77d73,
        800 : #9f7369,
        900 : #906156,
        A100 : #fff9f7,
        A200 : #ffcfc4,
        A400 : #ffa591,
        A700 : #ff9078,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);