/* For use in src/lib/core/theming/_palette.scss */
$md-beige: (
        50 : #f9f8f7,
        100 : #f1efea,
        200 : #e8e4dd,
        300 : #dfd9cf,
        400 : #d8d0c4,
        500 : #d1c8ba,
        600 : #ccc2b3,
        700 : #c6bbab,
        800 : #c0b4a3,
        900 : #b5a794,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #fff7eb,
        A700 : #ffecd1,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #000000,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

