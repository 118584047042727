/* For use in src/lib/core/theming/_palette.scss */
$md-gray: (
        50 : #f4f6f7,
        100 : #e3e9ea,
        200 : #d0dadd,
        300 : #bdcbcf,
        400 : #afbfc4,
        500 : #a1b4ba,
        600 : #99adb3,
        700 : #8fa4ab,
        800 : #859ca3,
        900 : #748c94,
        A100 : #ffffff,
        A200 : #e3f8fe,
        A400 : #afecff,
        A700 : #96e6ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #000000,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

